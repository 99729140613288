//Import React Library
import React, { useState } from "react";

//Import components
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Define animation handler for dropdown
const animatedComponents = makeAnimated();

//This components shows dropdowns to filter by category, subcategory, subject and title
export default function DropdownInput(props) {
  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      closeMenuOnSelect={true}
      components={animatedComponents}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.handleChange(e)}
      options={props.options}
      isDisabled={props.isDisabled}
    />
  );
}
