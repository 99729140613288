import React, { useState, useContext } from "react";

import {
  listNews
} from "@lexis/news-api";

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

const months = [
  { value: null, label: "Todos" },
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
]



// create the provider
export const MyProvider = (props) => {
  const [newsList, setNewsList] = useState(null);
  const [filteredNewsList, setFilteredNewsList] = useState(null);
  const [years, setYears] = useState(null);
  const [days, setDays] = useState(null);
  const [selectedYear, setSelectedYear] = useState({ value: null, label: "Todos los años" })
  const [selectedMonth, setSelectedMonth] = useState({ value: null, label: "Todos los meses" })
  const [selectedDay, setSelectedDay] = useState({ value: null, label: "Todos los días" })

  const [isDarkModeEnabled, setDarkMode] = useState(false);
  const [theme, setTheme] = useState("light");

  const updateThemeMode = (state) => {
    console.log("is dark mode enable :", state);
    setDarkMode(state);
    if (state === true) {
      setTheme("dark");
      console.log("setting to dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      console.log("setting to light");
      localStorage.setItem("theme", "light");
    }
  };

  //const []
  React.useEffect(() => {
    !newsList && fetchNewsList()
    !days && setDaysArray()
  }, [])

  const setDaysArray = () => {
    let rangeDays = Array.from({ length: 31 }, (x, i) => i + 1)
    let tempDays = []
    rangeDays.forEach((element, i) => {
      tempDays[i+1] = { value: element, label: element }
    });
    tempDays[0]={ value: null, label: "Todos" }
    setDays(tempDays)
  }

  const fetchNewsList = async () => {
    //console.log("email:", email);
    let temp = [];
    listNews("esilecstorage", "biblioteca_silec/SILECNEWS/").then((data) => {
      console.log("find news:", data);
      let temp = []
      data.data.forEach((element, i) => {
        temp[i] = {
          year: element.substr(27, 4),
          month: element.substr(38, 2),
          day: element.substr(40, 2),
          url: "https://esilecstorage.s3.amazonaws.com/"+element
        }
      });
      //console.log("parsed news:", temp);
      let years = temp.map(item => item.year).filter((value, index, self) => self.indexOf(value) === index)
      //console.log("years: ", years)
      let modyears = []
      years.forEach((element, i) => {
        modyears[i+1] = { value: element, label: element }
      })
      modyears[0]={ value: null, label: "Todos" }
      //console.log("mod year:", modyears)
      setNewsList(temp);
      setYears(modyears)
      //getYears()
    });
    return temp;
  };


  const filterNews = () => {
    let temp = newsList

    temp= selectedYear.value? temp.filter((data) => data.year === selectedYear.value)
      : temp;
    temp = selectedMonth.value
      ? temp.filter(
        (data) => data.month === selectedMonth.value
      )
      : temp;
    temp = selectedDay.value
      ? temp.filter((data) => parseInt(data.day) === selectedDay.value)
      : temp;
      console.log("filtered news list",temp)
      setFilteredNewsList(temp)
  }


  const resetFilters = () => {
    setSelectedDay({ value: null, label: "Todos los días" })
    setSelectedMonth({ value: null, label: "Todos los meses" })
    setSelectedYear({ value: null, label: "Todos los años" })
    setFilteredNewsList(null)
  }
  return (
    <MyContext.Provider
      value={{
        newsList,
        years,
        months,
        days,
        selectedYear, setSelectedYear,
        selectedMonth, setSelectedMonth,
        selectedDay, setSelectedDay,
        theme,
        isDarkModeEnabled,
        updateThemeMode,
        resetFilters, filterNews,
        filteredNewsList
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
