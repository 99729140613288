import React from "react";

//Import components from ui library
import { Card } from "@lexis/ui-library";

//Import styles
import "./styles.scss";

//Import favorito icon (star icon)
//import FavoritoIcon from "../FavoritoIcon/FavoritoIcon";

//Import custom title component
import { SectionTitle } from "@lexis/ui-library";

////Import state management provider
import { useMyContext } from "../../../Context/context";

export default function NewsList(props) {
  //Load states from state management provider
  const { months } = useMyContext();
  console.log("inside boolist: ", props.books)
  //console.log("inside boolist: ",props.subcategorias)*/
  return props.books ? (
    <div className="row  py-3 px-3 ">
      
      {props.books.length > 0 &&<p className="pb-3 news-subtitle">Se encontraron {props.books.length} documentos</p>}
      {props.books.length > 0 ? props.books.map((item) => {
        return (


          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <Card className="books-card">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="my-auto book-link cursor-pointer px-3 py-2"
                href={item.url}
              >
                Lexis News del {item.day} de {months.filter(month => month.value === item.month)[0].label} de {item.year}
              </a>
            </Card>
          </div>
        )
      }) :

        <p className="news-subtitle">No existen datos disponibles</p>
      }
    </div >)
    :

    <p className="news-subtitle" >Cargando</p>


}



