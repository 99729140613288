import React from "react";
import { MyProvider } from "./Context/context";
import Home from "./Components/Home/Home"

//A router from react-router-dom
import { BrowserRouter } from "@lexis/ui-library";

export default function Root(props) {
  return (
    <MyProvider>
      <BrowserRouter>
        <Home />
        
      </BrowserRouter>

    </MyProvider>);
}
