//Import React Library
import React from "react";

//Import components from Lexis UI Library
//Toogle button to set theme
import { DarkToggle } from "@lexis/ui-library";
// Lexis icon
import { LColor } from "@lexis/ui-library";
// Helper to check if device is mobile
import { isMobileOnly, Link } from "@lexis/ui-library";

//Import state management provider
import { useMyContext } from "../../../Context/context";

import { Helmet } from "react-helmet";

//This funtion provides  a navbar with Lexis icon, a title, toogle theme button and a signout button
export default function Navbar() {
  //Load states from state management provider
  const {
    isDarkModeEnabled,
    updateThemeMode,
    theme,
  } = useMyContext();

  return (
    <div
      className="row p-0 mx-0 my-auto py-2 px-3 header-site w-100"
      style={{ borderBottom: "1px var(--primaryText) solid" }}
    >
      <Helmet>
        <body
          className={
            localStorage.getItem("theme") !== null
              ? localStorage.getItem("theme")
              : theme
          }
        />
      </Helmet>
      <Link
        to="/"
        className="p-0 my-auto"
        style={{ fill: "var(--tertiaryText)" }}
        onMouseOver={(e) => (e.currentTarget.style.fill = "var(--primaryText)")}
        onMouseOut={(e) => (e.currentTarget.style.fill = "var(--tertiaryText)")}
      >
        <LColor width="50px" />
      </Link>
      {!isMobileOnly && (
        <h3 className="my-auto pl-4 gotham-rounded-medium">
          Lexis News
        </h3>
      )}
      <div className="col p-0 m-0"></div>

      <div className="row p-0 m-0 my-auto p-2">
        <DarkToggle
          isDarkModeEnabled={isDarkModeEnabled}
          updateThemeMode={updateThemeMode}
        />
      </div>
    </div>
  );
}
