import React from 'react'
import { useMyContext } from "../../Context/context"
import DropdownInput from "../Reusable/Filter/DropdownInput"
import { FaSearch, FaTrash, SectionTitle, Link } from "@lexis/ui-library";
import Navbar from "../Reusable/Navbar/Navbar"
//Import styles
import "./styles.scss";

import NewsList from "../Reusable/NewsList/NewsList"
export default function Home() {
    const { newsList, filteredNewsList,
        years,
        months,
        days,
        selectedYear, setSelectedYear,
        selectedMonth, setSelectedMonth,
        selectedDay, setSelectedDay,
        filterNews, resetFilters } = useMyContext()

    /*console.log("newsList: ", newsList)
    console.log("years: ", years)
    console.log("months: ", months)
    console.log("days: ", days)*/
    return (
        <React.Fragment>
            <Navbar />
            <div className="news-filter-container mx-auto">

                <SectionTitle color="var(--primaryText)" title="Lexis News" className="pt-4" />
                <div className="row mx-auto w-100 my-0 ">
                    <div className="col-12 col-md-6 col-xl-4 mt-3">
                        <DropdownInput
                            placeholder="Año"
                            value={selectedYear}
                            handleChange={(e) => (
                                setSelectedYear(e)
                            )}
                            options={years}
                            isDisabled={false} />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 mt-3">
                        <DropdownInput
                            placeholder="Mes"
                            value={selectedMonth}
                            handleChange={(e) => (
                                setSelectedMonth(e)
                            )}
                            options={months}
                            isDisabled={false} />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 mt-3">
                        <DropdownInput
                            placeholder="Día"
                            value={selectedDay}
                            handleChange={(e) => (
                                setSelectedDay(e)
                            )}
                            options={days}
                            isDisabled={false} />
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="row w-100 justify-content-end mx-0">
                        <Link to="/search">
                            <button
                                className="btn advanced-filter-icon mr-3"
                                onClick={() => filterNews()}
                            >
                                <FaSearch />
                            </button>
                        </Link>
                        <Link to="/">
                            <button
                                className="btn advanced-filter-icon mr-0"
                                onClick={() => resetFilters()}
                            >
                                <FaTrash />
                            </button>
                        </Link>
                    </div>
                </div>
                {filteredNewsList && <NewsList books={filteredNewsList} />}
            </div>
        </React.Fragment>
    )
}
